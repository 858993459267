@media print{
	.top-bar-left{
		width:150px;
	}
	h1#reportTitle, h2.identifiedGaps-Title{
		font-size:1.8rem;
		
	}
	h2.identifiedGaps-Title{
		margin-bottom:15rem;
	}
	#skills-report-block img{
		width:50px;
	}
	#skills-report-block .cell{
		width:93%;
	}
	.skills-report{
		border-bottom:1px solid #000
	}
	.printOnly{
		display:block;
	}
	.printHide{
		display:none;
	}
	.imgPrintFix{
		padding-bottom:50px;
	}
	.what-next{
		height:450px;
	}
}