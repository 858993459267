/* uncss:ignore start */

.primary{
	color:$primary-color;
}

#SkillsHive-title{
	#animation_container, canvas{
		max-width: 80%;
		width: 80% !important;
		position:relative !important;
		height:auto !important;
		margin: 0 auto;
		
	}
}

#animation_container{
	z-index: 100;
}

#skills-cone-container{

	background: url(../img/Header-image4.png) no-repeat bottom center scroll;
    background-size: contain;
    padding: 0% 4% 8% 4%;


    &:after, &:before{
		
	}
	&:after{
		    height: 100%;
		    width: 100%;
		    content: '';
		    bottom: 0;
		    background-image: url(../img/female-designer.png);
		    background-size: cover;
		    position: absolute;
		    background-repeat: no-repeat;
		    background-position: bottom right;
		    left: 0;
		    opacity: 0.3;
		    z-index: 0;

	}
	

	/*background-color:$secondary-color;*/
	color:$white;
	h1, h2, h3, h4, h5, h6, a{
		color:$white;
		span{
			color:$primary-color;
		}
		.info-icon{
			border-radius: 0%;
			font-size: 1.5rem;
			width: 26px;
			height: 27px;
			padding-left: 0;
			padding-top: 2px;
			vertical-align: super;
			cursor: pointer;
			color: #E61853;
			margin-left: 10px;
			
			z-index: 10;
			position: absolute;
		}
	}
	a{
		text-decoration:underline;
		span{
			text-decoration:none;
			color:$white;
			display:inline-block;
			padding-right:5px;
		}
	}

	#animation_container, canvas{
		max-width:100%;
		width:100% !important;
		position:relative !important;
		height:auto !important;
		margin: 0 auto;
	}



	
}
#digital-skills-tooltip, #createReport, #digital-skills-contact{
	    
	    max-width: 800px;
	    background: $white;
	    border-color: #85858b;
	    border-radius: 25px;
	    padding: 2rem;

	    .button{
	    	margin: 0 0 0
	    }

	    label{
	    }
	    img{
	    	padding-left: 20px;
	    }

	    &:not(.large) {
			//width: 40%;
	    }
	    .close-button{
	    	color:#aaa;
	    }
	    [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	    	border-radius:10px;
	    	border:1px solid #aaa;
	    	box-shadow:none;
	    }
}
#digital-skills-tooltip{
	background: $secondary-color;
	color:$white;
}

#digital-skills-contact{

	input[type='submit']{
		display: block;
    	width: 100%;
	    vertical-align: middle;
	    margin: 0 0 0;
	    font-family: inherit;
	    padding: 0.85em 1em;
	    -webkit-appearance: none;
	    border: 1px solid transparent;
	    border-radius: 0;
	    transition: background-color 0.25s ease-out, color 0.25s ease-out;
	    font-size: 0.9rem;
	    line-height: 1;
	    text-align: center;
	    cursor: pointer;
	    background-color: $primary-color;
	    color: #fefefe;
	        margin-top: 2rem;
	}
    
}

.text-align-left{
	text-align:left;
	h1, h2, h3, h4, h5, h6, p{
		text-align:left;
	}
}
.hc_intro{
	
	.click-icon{
		max-width:100px;
		margin-bottom:50px;
	}
}
.digital-skills-tooltip{
	border:1px solid $white;
	border-radius:$global-radius;
	padding:20px;
	position:absolute;
	max-width:500px;
	z-index:100;
	background:$secondary-color;
	visibility:hidden;
	opacity: 0;
	transition: visibility 0.3s, opacity 0.3s;
	&.show{
		visibility:visible;
		opacity: 1;
		transition: visibility 0.3s, opacity 0.3s;
	}
	&:after{
		position: absolute;
	    top: 0px;
	    right: 1rem;
	    content: '+';
	    transform:rotate(45deg);
	    font-size:2rem;
	    cursor:pointer;
	}
	.button{
		color:$white;
		border:1px solid $white;
		width:100%;
	}
}


ul.skills-block{
	color:$white;
	font-size:rem-calc(10);
	padding:0px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    
	li{
		display: inline-block;
		@include breakpoint(large) {
			display: block;
		}

	    background-color: #8b8b91;	    
	    padding: 3px 15px 10px 10px;
	    border-radius: 0px 4px 4px 0px;
	    margin: 0.5rem 9px 0.1rem 15px;
	    text-transform: uppercase;
	    position: relative;
	    max-height: 26px;
	    font-size: 0.9rem;
	    width:fit-content;
	    line-height:23px;

		&:before{
			width: 15px;
		    content: " ";
		    position: absolute;
		    left: -14px;
		    top: 0;
		    display: inline-block;
		    height: 26px;
			background-image: url(../img/skills-report/tag-pointer.svg);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			
		}
	}
}


#skills-content-container{
	background-color:$white;
	position:relative;
	
	.skill-content-block{
		padding-top:1.5rem;
		h1, h2, h3, h4, h5, h6, p{
			text-align:left;
		}
		p{
			min-height:160px;
			max-height:160px;
		}
		h3{
			img{
				max-width:28px;
			}
		}
		.sub-cat{
			color:$white;
			font-size:rem-calc(10);
			padding:0px;
			border-bottom: 1px solid $light-medium-gray;
		    padding-bottom: 1rem;
		    margin-bottom: 1rem;
		    min-height: 60px;
			span{
			    background-color: $light-medium-gray;
			    display: inline-block;
			    padding: 5px 10px 3px;
			    border-radius: 0px 4px 4px 0px;
			    margin: 0.1rem 9px 0.1rem 15px;
			    text-transform: uppercase;
			    position: relative;
			    max-height: 23px;
				background-color: #8b8b91;

    			&:before{
    				width: 15px;
				    content: " ";
				    position: absolute;
				    left: -14px;
				    top: 0;
				    display: inline-block;
				    height: 23px;
					background-image: url(../img/skills-report/tag-pointer.svg);
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;

    				
    			}
			}
		}
	}
	.content-bar, .sub-content-bar{
		height:10px;
		width:100%;
		margin-bottom:0.75rem;
		background-color:$light-medium-gray;

	}
	.sub-content-bar{
		width:75%;
	}
	.additional-info-block{
		cursor:pointer;
		.button{
			opacity:1;
			background-color:$secondary-color;
			width:100%;
			margin-top:10px;
			transition:opacity 0.3s;
			i{
				margin-right:5px;
			}
			&:before{
				background-color:$light-gray;
			}
			&:hover{
				color:$secondary-color !important;
			}
		}
		&:hover{
			.button{
				opacity:1;
				transition:opacity 0.3s;
			}
		}
	}
}

#skill-cone-signup{
	background:url('/assets/img/Skills_Comb_report@2x.jpg') no-repeat center center;
	background-size:cover;
	background-color:$white;
	@include breakpoint(small only){
		padding-bottom:80%;
		background-position:-62vw bottom;
		background-size: auto 80vw;
	}
	.checklist{
		margin-top:rem-calc(20);
		margin-bottom:rem-calc(20);
		p{
			padding-left:50px;
			position:relative;
			&:before{
				content:'\f058';
				font-family:'Font Awesome 5 Pro';
				font-weight: bold;
				position:absolute;
				left:10px;
				top:-5px;
				font-size:rem-calc(30);
				color:$light-medium-gray;
			}
		}
	}
}


.skills-cone-button{
	width:100%;
	background-color:$bright-green;
	font-size:rem-calc(20);
	border-radius:20px;
	&:before{
		background-color:$bright-green;
	}
	&:hover{
		color:$bright-green !important;
		background-color:$bright-green;
	}

}
#full-report{
	background-color:$white;
}
#skills-cone-additional{
	background-color:$secondary-color;
	color:$white;
	position:relative;
	h1, h2, h3, h4, h5, h6, a{
		color:$white;
	}
	&:before{
		background-color:$white;
		height:50px;
		width:50px;
		content:'';
		top:-25px;
		left:50%;
		position:absolute;
		transform:translateX(-50%) rotate(45deg);
	}
}



#skills-comb-view{
	img{
		max-width:rem-calc(550);
	}
}
.darkSection{
	color:$white;
	h1{
		span{
			font-weight:700;
		}
	}
}
#SkillsHive-logo{
	margin-bottom:rem-calc(50);
	img{
		max-width:rem-calc(300);
	}
}
#SkillsHive-title{
	background:url(/assets/img/Header-image4.jpg) no-repeat bottom center scroll;
	h1{
		margin-bottom:rem-calc(50);
	}
	.share{
		margin-top:rem-calc(50);
		a{
			color:$white;
			text-decoration:underline;
		}
	}
}
#SkillsHive-footer{
	background:url(/assets/img/Header-image4.png) no-repeat bottom center scroll;
}
#identifiedGaps-Container{
	background-color:$white;
}
.seperator{
	border-bottom:2px dotted $white;
	margin-bottom:4%;
}
.identifiedGaps-Title{
	font-size:rem-calc(38);
	margin-bottom:rem-calc(100);
}

.skills-report{
	margin-bottom:rem-calc(50);
	img{
		max-width:rem-calc(50);
		display:inline-block;
		margin-top:rem-calc(-14);
		margin-right:rem-calc(10)
	}
	h4{
		text-transform:lowercase;
		padding-bottom:0;
		display: inline-block;
	}

	.skillDescription{
		h4{
			display: block;
		}

		.button{
			margin: 0 0 1rem 0;
			border-radius: 100px;
		}

		strong{
			color: $primary-color;
		}
	}

	ul{
		li{
			// font-weight: 700;
		 //    position: relative;
		 //    /* padding-left: 25px; */
		 //    border-radius: 15px;
		 //    margin: 0.1rem;
		 //    background-color: #ccc;
		 //    display: inline-block;
		 //    padding: 10px 15px;
			// &:before{
			//    content: '';
			   
			// }
		}
	}
}

#identifiedGaps-Container{

    background: #181824;

    h1,h2,h3,h4,h5,h6,p{
    	color: $white;
    }
}

#skills-report-block{

	.skillName{
		font-size: 2.5rem;
		text-transform: capitalize;
	}

	.skillDescription{
		h4{
			font-size: rem-calc(24);
			color: $primary-color;
			margin-top: 2rem;
		}
		p{
			margin-bottom: 2rem;
		}

		strong{
			color: $primary-color;
		}
	}
}

#what-next-title{
	margin-bottom:rem-calc(50);
}
.what-next{
	& > div{
		min-height:rem-calc(135);
	}
	img{
		margin-bottom:rem-calc(50);
		max-height:rem-calc(135);
	}
	.button{
		margin-top:rem-calc(50);
	}
}
.roundButton{
	border-radius:rem-calc(10)
}

// Button Animation
.skew-hover{
  overflow:hidden;
  position:relative;
  z-index:2;
  color:$white !important;
  &:before{
    position:absolute;
    z-index:-1;
    background-color:$bright-green;
    width:100%;
    height:100%;
    left:0;
    top:0;
    content:'';
    display:block;
  }
  &:after{
    content:'';
    width:150%;
    height:120%;
    display:block;
    background-color:$white;
    position:absolute;
    left:-170%;
    top:0;
    transform: skewX(20deg);
    z-index:-1;
    transition:left 0.5s;
  }
  &:hover{
    color:$bright-green !important;
    transition:color 0.5s;
    &:after{
      left:-20%;
      transition:left 0.5s;
    }
  }
}
.hollow.skew-hover{
  &:before{
    background:transparent;
  }
  &.light{
    color:$primary-color !important;
    &:after{
      background:$primary-color;
    }
    &:hover{
      color:$white !important;
      border-color:$primary-color;
    }
  }
  &.dark{
    color:$secondary-color !important;
  }
}

#reportTitle{

	@include breakpoint(medium) {
		text-align: left;
	}
}

#shareBlock{
	@include breakpoint(medium) {
			//text-align: left !important;
		}
	#mainShareLink{
		@include breakpoint(medium) {
			text-align: left !important;
		}
	}
	ul{
		margin-left: 0rem;		
	}

}

.previous-reports{
	@include breakpoint(medium) {
		//text-align: left;
    	margin-top: 2rem;

    	h5{
    		//text-align: left;
    	}
	}
}

.hc_intro{
	z-index: 499;
}

#skillsTitleBlock{

	z-index: 500;
	 margin-left: 0rem;
	
	@include breakpoint(large) {
		margin-left: 6rem;
	}

	h2{
		font-weight: lighter;
	}
	
	@include breakpoint(medium) {
		h2{
		font-size: 2.5rem;
		}
	}

	@include breakpoint(xlarge) {
		h2{
		font-size: 3.5rem;
		}
	}

	.strong{
		line-height: 1.8rem;
    	font-size: 1.1rem;
	}
	

	

	@include breakpoint(small only) {
		

		h2 {
		    font-weight: lighter;
		    font-size: 2.5rem;
		    text-align: center;
		    margin-top: 2rem;
		}
	

	}

    
}

	.smaller{
		font-size: 0.8rem;
    	line-height: 1.8rem;
	}


.close-button{
	color: $white; 
	&:hover{
		color: $primary-color;
	}
}


	#animation_container, #SkillsHive-title canvas{

	}

	#digital-skills-contact{
		margin-top: 0;
		height: 100vh;

		@include breakpoint(small only) {
			border-radius: 0;

		}
		
		@include breakpoint(medium) {
			margin-top: 1vh;
			height: 99vh;
			margin-bottom: 1vh;
			width: 570px;
		}

		//background-color: rgba($secondary-color, 0.8);
		h1,h2,h3,h4,h5,h6,p{
	    	//color: $white !important;
	    }
	}

	.hubspot-link__container{
		display: none !important;
	}

	.hs-form-iframe{
		height: 80vh;
	}

	fieldset {
	    max-width: 100% !important;
	}


#enquires-icon{
	cursor: pointer;
}
footer{
	
	margin-top:-25px;
}

.previous-reports{
	ul{
		li{
		    display: inline-flex;
		    width: max-content;
		    padding: 5px 10px;
		}
	}
}

/* uncss:ignore end */
