/**************************************** COOKIE  ****************************************/

// Animation

.cc-window {
  opacity: 1;
  
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  transition: opacity 1s ease;


}

.cc-window.cc-invisible {
  opacity: 0;
}

/* only animate ifhas class 'cc-animate' */
.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -moz-transition: transform 1s ease;
  -ms-transition: transform 1s ease;
  -o-transition: transform 1s ease;
  transition: transform 1s ease;
}
.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em);
}
.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em);
}
.cc-animate.cc-revoke.cc-active.cc-top {
  transform: translateY(0);
}
.cc-animate.cc-revoke.cc-active.cc-bottom {
  transform: translateY(0);
}
.cc-revoke:hover {
  transform: translateY(0);
}

.cc-grower {
  /* Initially we don't want any height, and we want the contents to be hidden */
  max-height: 0;
  overflow: hidden;

  /* Set our transitions up. */
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
}

// Base 
/* the popup window */
.cc-window, .cc-revoke {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box; /* exclude padding when dealing with width */

  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px; /* by setting the base font here, we can size the rest of the popup using CSS `em` */
  line-height: 1.5em;

  display: flex;
  flex-wrap: nowrap;

  /* the following are random unjustified styles - just because - should probably be removed */
  z-index: 9999;
}

.cc-window.cc-static {
  position: static;
}

/* 2 basic types of window - floating / banner */
.cc-window.cc-floating {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box; /* exclude padding when dealing with width */

  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px; /* by setting the base font here, we can size the rest of the popup using CSS `em` */
  line-height: 1.5em;

  display: flex;
  flex-wrap: nowrap;

  /* the following are random unjustified styles - just because - should probably be removed */
  z-index: 9999;
  padding: 2em;
  max-width: 24em; /* 1em == 16px therefore 24em == 384px */
  flex-direction: column;
  box-shadow:0px 0px 5px rgba(0, 0, 0, 0.2);
}
.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  flex-direction:  row;
}

.cc-revoke {
  padding: .5em;
}
.cc-revoke:hover {
  text-decoration: underline;
}

.cc-header {
  font-size: 18px;
  font-weight: bold;
}

/* clickable things */
.cc-btn, .cc-link, .cc-close, .cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: .8;
  display: inline-block;
  padding: .2em;
  text-decoration: underline;
}
.cc-link:hover {
  opacity: 1;
}
.cc-link:active, .cc-link:visited {
  color: initial;
}


.cc-btn {
  display: block;
  padding: .4em .8em;
  font-size: 0.9em;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
}

.cc-banner .cc-btn:last-child {
  min-width: 140px;
}

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent;
}

.cc-highlight .cc-btn:first-child:hover,
.cc-highlight .cc-btn:first-child:focus {
  background-color: transparent;
  text-decoration: underline;
}


.cc-close {
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
  font-size: 1.6em;
  opacity: .9;

  /* seeing as this contains text and not an image, the element taller than it is wide (because it is text) */
  /*  - we want it to be a square, because it's acting as an icon */
  /*  - setting the line height normalises the height */
  line-height: .75;
}
.cc-close:hover,
.cc-close:focus {
  opacity: 1;
}


//Layout

/* This file should contain CSS that modifies the popup layout. */
/* By layout, we mean the physical position of the elements on the popup window, and the margin / padding around those elements. */


.cc-revoke.cc-top {top:0; left: 3em; border-bottom-left-radius:.5em;border-bottom-right-radius:.5em;}
.cc-revoke.cc-bottom {bottom:0; left: 3em; border-top-left-radius:.5em;border-top-right-radius:.5em;}
.cc-revoke.cc-left{left: 3em; right: unset;}
.cc-revoke.cc-right{right: 3em; left: unset;}

/**************************************** FLOATING ****************************************/

/* these classes position the floating element */
.cc-top {top: 1em} .cc-left {left: 1em} .cc-right {right: 1em} .cc-bottom {bottom: 1em}


/* links that are direct decendants should be displayed as block */
.cc-floating>.cc-link {
  margin-bottom: 1em
}

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;
}


.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto;
}

/**************************************** BANNER ****************************************/

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  left: 0; right: 0; top: 0;
}
.cc-banner.cc-bottom {
  left: 0; right: 0; bottom: 0;
}

.cc-banner .cc-message {
  flex: 1;
}

/* COMPLIANCE BOX */

.cc-compliance {
  display: flex;
  align-items: center;
  align-content: space-between;
}
.cc-compliance>.cc-btn {
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: .5em;
}


// Media


@media print {
  .cc-window, .cc-revoke {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {white-space: normal;}
}

/* dimensions for 'iPhone6 Plus' and lower */
@media screen and (max-width: 414px) and (orientation: portrait),
       screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {top:0;}
  .cc-window.cc-bottom {bottom:0;}
  .cc-window.cc-banner,.cc-window.cc-right,.cc-window.cc-left {left:0;right:0;}

  .cc-window.cc-banner {flex-direction: column;}
  .cc-window.cc-banner .cc-compliance {flex: 1}
  .cc-window.cc-floating {max-width: 500px; font-size:0.8rem}
  .cc-window .cc-message {margin-bottom: 1em}
  .cc-window.cc-banner {align-items: unset;}
}

/* iPhone 6 */
@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 667px) {
}

/* iPhone 6 plus */
@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) {
}

/* iPhone 5 & 5S */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 568px) {}

/* iPhone 2G - 4S */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {}



//Themes

/* Edgeless */

.cc-theme-edgeless.cc-window {
  padding: 0;
}

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em;
  margin-bottom: 1.5em;
}

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%;
}

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em;
}

.cc-floating.cc-theme-edgeless .cc-btn +.cc-btn {
  margin-left: 0;
}
/* Classic */

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  flex: none;
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
}
.cc-theme-classic .cc-btn:last-child {
  min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}
