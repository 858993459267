.nav-grad{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#084a69+0,001b3c+99 */
	background: #084a69; /* Old browsers */
	background: -moz-linear-gradient(top, #084a69 0%, #2daae1 99%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #084a69 0%,#2daae1 99%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #084a69 0%,#2daae1 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#084a69', endColorstr='#2daae1',GradientType=0 ); /* IE6-9 */
}

.blue-grad{
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6dc4de+0,41a2be+100 */
	background: #6dc4de; /* Old browsers */
	background: -moz-linear-gradient(top, #6dc4de 0%, #41a2be 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #6dc4de 0%,#41a2be 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #6dc4de 0%,#41a2be 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6dc4de', endColorstr='#41a2be',GradientType=0 ); /* IE6-9 */
}