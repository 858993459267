nav{

	a{
		color: $white;
	}	
	
	.dropdown.menu a {
	    padding: 0.7rem 11px;
	   
	}
	
	.dropdown.menu li
	{
		&:last-child{
			a{
				padding-right: 0;
			}
		}
	}


}

.off-canvas{
	li{
		width: 100%;
	}
}

nav.top-bar{
	margin-top: 0;
    padding: 0.5rem 24px;
    //max-width: 100% !important;
    //width: 100% !important;

		
    		padding: 20px 40px;
		
    transition: background-color 3s ease;

    padding: 24px;

    img{
    	width: 100%;
    	transition: all 0.5s ease;
    	max-width: 240px;
		
		&.small{
			max-width: 190px;
		}
    	
		
		@include breakpoint(medium) {
			//max-width: 360px;
	    	//margin: 0 auto;
	    	//display: block;
    	}
    }
		
	@include breakpoint(medium only) {
		@include breakpoint(landscape only) {
	 		img{
	 			max-width: 240px;
	 		}
	 	}
	}
	
	@include breakpoint(large only) {
		@include breakpoint(portrait only) {
	 		img{
	 			max-width: 240px;
	 		}
	 	}
	}

	@media screen and (min-width: 1020px) and (max-width: 1120px) {
		img{
	 			max-width: 240px;
	 		}
	}

	//1120 -> 1020
	

    .top-bar-right{
    	@include breakpoint(medium down) {
    		//position: absolute;
    		//top: 75px;
    	}
    }

    &.is-anchored{
					
    }
    &.is-stuck{
		background-color: rgba(get-color(secondary), 0.8);
		padding: 20px 40px;
    }


}

nav.title-bar{
	display: none;
	    padding: 0.5rem;
    background: #181824;
	    height: 95px;
	 .title-bar-title{
    	img{
    		width: 200px;
    		display: block;
	    	position: absolute;
		    left: 24px;
		    top: 24px;
		    @include breakpoint(small only) {
		    	left: 0;
			    top: 24px;
			    margin: 0 auto;
			    right: 0;
		    }
    	}
    	
    }
}

.top-bar .top-bar-right {
    width: 350px;
	}