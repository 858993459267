body{
	background: #181824;
	}
#nav-container{
	background: #181824; 
	background-size: cover;

	 @include breakpoint(medium) {
		background: #181824; 
		background-size: cover;
	}

 	
	background-color: get-color(primary);
}

#container{
	/*
	&:before {
	  content: "";
	  position: absolute;
	  top: 0; 
	  left: 0;
	  right: 0;
	  margin: 0 auto;
	  width: $global-width;
	  height: 6800px;
	  opacity: .4; 
	  z-index: -1;
	  background:  url(../img/bg-cover.png);
	}
	*/
}

h1.pink, h2.pink,  h3.pink, p.pink{
	color: get-color(primary);
}
.white{
	color: $white !important;
}

h2{

}

#vid-reveal{
	padding-top: 50px;
}


.mpu-banner {
    max-width: 100%;
    min-height: 250px;
    z-index: 20;
    position: relative;
    border: solid 1px gray;

}

.mpu-container {
	margin-bottom: 2.60417rem;
	text-align: center;

	p{
	text-align: center !important;;

	}
}

.sticky-container{
	z-index: 99999;
}

fieldset {
    max-width: 100% !important;
}

 .hs-input {
    width: 100% !important;
}

ul {
    list-style-type: none !important;
}

label > [type='checkbox'], label > [type='radio'] {
    max-width: 20px !important;
}

.hs-richtext{
	text-align: left !important;
		font-size: 0.8rem !important;
	p{
		text-align: left !important;
		font-size: 0.8rem;
	}
}

#section7 form [type='submit'], #section7 form [type='button'] {

    margin-top: 2rem !important;
    width: 100% !important;
    cursor: pointer;
}

#section7 form {
	a{
		color: $white;
		text-decoration: underline;
	}

}
.printHide{
	display:block;
}
.printOnly{
	display:none;
}





