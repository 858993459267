footer{
  position: relative;
  padding: 20% 4%;
  color: $white;


  @include breakpoint(medium) {
    padding: 4% 4% 8% 4%;
  }

  img{
  	max-width: 242px;
    margin-bottom: 20px;
  }

  p{
  	font-size: 13px;
  }

  .privacy{
    text-align: center;
  }

}