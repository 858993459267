@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "museo-sans-rounded", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 84.375rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 1.875rem;
      padding-left: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-container {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }
  .grid-container.fluid {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 1.875rem;
        padding-left: 1.875rem; } }
    @media print, screen and (min-width: 64em) {
      .grid-container.fluid {
        padding-right: 2.5rem;
        padding-left: 2.5rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

@media screen and (min-width: 75em) {
  .grid-x > .xlarge-shrink, .grid-x > .xlarge-full, .grid-x > .xlarge-1, .grid-x > .xlarge-2, .grid-x > .xlarge-3, .grid-x > .xlarge-4, .grid-x > .xlarge-5, .grid-x > .xlarge-6, .grid-x > .xlarge-7, .grid-x > .xlarge-8, .grid-x > .xlarge-9, .grid-x > .xlarge-10, .grid-x > .xlarge-11, .grid-x > .xlarge-12 {
    flex-basis: auto; } }

@media screen and (min-width: 90em) {
  .grid-x > .xxlarge-shrink, .grid-x > .xxlarge-full, .grid-x > .xxlarge-1, .grid-x > .xxlarge-2, .grid-x > .xxlarge-3, .grid-x > .xxlarge-4, .grid-x > .xxlarge-5, .grid-x > .xxlarge-6, .grid-x > .xxlarge-7, .grid-x > .xxlarge-8, .grid-x > .xxlarge-9, .grid-x > .xxlarge-10, .grid-x > .xxlarge-11, .grid-x > .xxlarge-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .grid-x > .xlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xlarge-1 {
    width: 8.33333%; }
  .grid-x > .xlarge-2 {
    width: 16.66667%; }
  .grid-x > .xlarge-3 {
    width: 25%; }
  .grid-x > .xlarge-4 {
    width: 33.33333%; }
  .grid-x > .xlarge-5 {
    width: 41.66667%; }
  .grid-x > .xlarge-6 {
    width: 50%; }
  .grid-x > .xlarge-7 {
    width: 58.33333%; }
  .grid-x > .xlarge-8 {
    width: 66.66667%; }
  .grid-x > .xlarge-9 {
    width: 75%; }
  .grid-x > .xlarge-10 {
    width: 83.33333%; }
  .grid-x > .xlarge-11 {
    width: 91.66667%; }
  .grid-x > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .grid-x > .xxlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxlarge-1 {
    width: 8.33333%; }
  .grid-x > .xxlarge-2 {
    width: 16.66667%; }
  .grid-x > .xxlarge-3 {
    width: 25%; }
  .grid-x > .xxlarge-4 {
    width: 33.33333%; }
  .grid-x > .xxlarge-5 {
    width: 41.66667%; }
  .grid-x > .xxlarge-6 {
    width: 50%; }
  .grid-x > .xxlarge-7 {
    width: 58.33333%; }
  .grid-x > .xxlarge-8 {
    width: 66.66667%; }
  .grid-x > .xxlarge-9 {
    width: 75%; }
  .grid-x > .xxlarge-10 {
    width: 83.33333%; }
  .grid-x > .xxlarge-11 {
    width: 91.66667%; }
  .grid-x > .xxlarge-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -1.875rem;
      margin-right: -1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x {
      margin-left: -2.5rem;
      margin-right: -2.5rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 3.75rem);
      margin-left: 1.875rem;
      margin-right: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .cell {
      width: calc(100% - 5rem);
      margin-left: 2.5rem;
      margin-right: 2.5rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 2.5rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 2.5rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 2.5rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 2.5rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 2.5rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 2.5rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 2.5rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 2.5rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 2.5rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 2.5rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 2.5rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 2.5rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 3.75rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 3.75rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 3.75rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 3.75rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 3.75rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 3.75rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 3.75rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 3.75rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 3.75rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 3.75rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 3.75rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 3.75rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 3.75rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 3.75rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 3.75rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 3.75rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 3.75rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 3.75rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 3.75rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 3.75rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 3.75rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 3.75rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 3.75rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 3.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 5rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 5rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 5rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 5rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 5rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 5rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 5rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 5rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 5rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 5rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 5rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 5rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 5rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 5rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 5rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 5rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 5rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 5rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 5rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 5rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 5rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 5rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 5rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 5rem); }
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 5rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 5rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 5rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 5rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 5rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 5rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 5rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 5rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 5rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 5rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 5rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 5rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-x > .xlarge-auto {
      width: auto; }
    .grid-margin-x > .xlarge-shrink {
      width: auto; }
    .grid-margin-x > .xlarge-1 {
      width: calc(8.33333% - 5rem); }
    .grid-margin-x > .xlarge-2 {
      width: calc(16.66667% - 5rem); }
    .grid-margin-x > .xlarge-3 {
      width: calc(25% - 5rem); }
    .grid-margin-x > .xlarge-4 {
      width: calc(33.33333% - 5rem); }
    .grid-margin-x > .xlarge-5 {
      width: calc(41.66667% - 5rem); }
    .grid-margin-x > .xlarge-6 {
      width: calc(50% - 5rem); }
    .grid-margin-x > .xlarge-7 {
      width: calc(58.33333% - 5rem); }
    .grid-margin-x > .xlarge-8 {
      width: calc(66.66667% - 5rem); }
    .grid-margin-x > .xlarge-9 {
      width: calc(75% - 5rem); }
    .grid-margin-x > .xlarge-10 {
      width: calc(83.33333% - 5rem); }
    .grid-margin-x > .xlarge-11 {
      width: calc(91.66667% - 5rem); }
    .grid-margin-x > .xlarge-12 {
      width: calc(100% - 5rem); } }
  @media screen and (min-width: 90em) {
    .grid-margin-x > .xxlarge-auto {
      width: auto; }
    .grid-margin-x > .xxlarge-shrink {
      width: auto; }
    .grid-margin-x > .xxlarge-1 {
      width: calc(8.33333% - 5rem); }
    .grid-margin-x > .xxlarge-2 {
      width: calc(16.66667% - 5rem); }
    .grid-margin-x > .xxlarge-3 {
      width: calc(25% - 5rem); }
    .grid-margin-x > .xxlarge-4 {
      width: calc(33.33333% - 5rem); }
    .grid-margin-x > .xxlarge-5 {
      width: calc(41.66667% - 5rem); }
    .grid-margin-x > .xxlarge-6 {
      width: calc(50% - 5rem); }
    .grid-margin-x > .xxlarge-7 {
      width: calc(58.33333% - 5rem); }
    .grid-margin-x > .xxlarge-8 {
      width: calc(66.66667% - 5rem); }
    .grid-margin-x > .xxlarge-9 {
      width: calc(75% - 5rem); }
    .grid-margin-x > .xxlarge-10 {
      width: calc(83.33333% - 5rem); }
    .grid-margin-x > .xxlarge-11 {
      width: calc(91.66667% - 5rem); }
    .grid-margin-x > .xxlarge-12 {
      width: calc(100% - 5rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -1.25rem;
  margin-left: -1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -1.875rem;
      margin-left: -1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -2.5rem;
      margin-left: -2.5rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -1.25rem;
  margin-left: -1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -1.875rem;
      margin-left: -1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -2.5rem;
      margin-left: -2.5rem; } }

.grid-padding-x > .cell {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 1.875rem;
      padding-left: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-x > .cell {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 75em) {
  .xlarge-up-1 > .cell {
    width: 100%; }
  .xlarge-up-2 > .cell {
    width: 50%; }
  .xlarge-up-3 > .cell {
    width: 33.33333%; }
  .xlarge-up-4 > .cell {
    width: 25%; }
  .xlarge-up-5 > .cell {
    width: 20%; }
  .xlarge-up-6 > .cell {
    width: 16.66667%; }
  .xlarge-up-7 > .cell {
    width: 14.28571%; }
  .xlarge-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 90em) {
  .xxlarge-up-1 > .cell {
    width: 100%; }
  .xxlarge-up-2 > .cell {
    width: 50%; }
  .xxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxlarge-up-4 > .cell {
    width: 25%; }
  .xxlarge-up-5 > .cell {
    width: 20%; }
  .xxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxlarge-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 2.5rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 2.5rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 2.5rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 2.5rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 2.5rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 2.5rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 2.5rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 2.5rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 2.5rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 2.5rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 2.5rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 2.5rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 2.5rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 2.5rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 2.5rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 2.5rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 3.75rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 3.75rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 3.75rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 3.75rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 3.75rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 3.75rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 3.75rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 3.75rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 2.5rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 3.75rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 2.5rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 3.75rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 2.5rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 3.75rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 2.5rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 3.75rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 2.5rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 3.75rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 2.5rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 3.75rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 2.5rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 3.75rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 2.5rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 3.75rem); }
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 5rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 5rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 5rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 5rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 5rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 5rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 5rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 5rem); } }

@media screen and (min-width: 75em) {
  .grid-margin-x.xlarge-up-1 > .cell {
    width: calc(100% - 5rem); }
  .grid-margin-x.xlarge-up-2 > .cell {
    width: calc(50% - 5rem); }
  .grid-margin-x.xlarge-up-3 > .cell {
    width: calc(33.33333% - 5rem); }
  .grid-margin-x.xlarge-up-4 > .cell {
    width: calc(25% - 5rem); }
  .grid-margin-x.xlarge-up-5 > .cell {
    width: calc(20% - 5rem); }
  .grid-margin-x.xlarge-up-6 > .cell {
    width: calc(16.66667% - 5rem); }
  .grid-margin-x.xlarge-up-7 > .cell {
    width: calc(14.28571% - 5rem); }
  .grid-margin-x.xlarge-up-8 > .cell {
    width: calc(12.5% - 5rem); } }

@media screen and (min-width: 90em) {
  .grid-margin-x.xxlarge-up-1 > .cell {
    width: calc(100% - 5rem); }
  .grid-margin-x.xxlarge-up-2 > .cell {
    width: calc(50% - 5rem); }
  .grid-margin-x.xxlarge-up-3 > .cell {
    width: calc(33.33333% - 5rem); }
  .grid-margin-x.xxlarge-up-4 > .cell {
    width: calc(25% - 5rem); }
  .grid-margin-x.xxlarge-up-5 > .cell {
    width: calc(20% - 5rem); }
  .grid-margin-x.xxlarge-up-6 > .cell {
    width: calc(16.66667% - 5rem); }
  .grid-margin-x.xxlarge-up-7 > .cell {
    width: calc(14.28571% - 5rem); }
  .grid-margin-x.xxlarge-up-8 > .cell {
    width: calc(12.5% - 5rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }
  @media screen and (min-width: 75em) {
    .small-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .small-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .small-margin-collapse > .xlarge-3 {
      width: 25%; }
    .small-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .small-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .small-margin-collapse > .xlarge-6 {
      width: 50%; }
    .small-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .small-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .small-margin-collapse > .xlarge-9 {
      width: 75%; }
    .small-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .small-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .small-margin-collapse > .xlarge-12 {
      width: 100%; } }
  @media screen and (min-width: 90em) {
    .small-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .small-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .small-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .small-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .small-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .small-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .small-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .small-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .small-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .small-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .small-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .small-margin-collapse > .xxlarge-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .medium-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .medium-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .large-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .large-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 1.25rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 1.25rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 1.25rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 1.25rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 1.25rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 1.25rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 1.25rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 1.25rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 1.25rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 1.25rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 1.25rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 1.25rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.875rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 1.875rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 1.875rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.875rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 1.875rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 1.875rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.875rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 1.875rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 1.875rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.875rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 1.875rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 2.5rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 2.5rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 2.5rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 2.5rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 2.5rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 2.5rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 2.5rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 2.5rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 2.5rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 2.5rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 2.5rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 2.5rem); } }

@media screen and (min-width: 75em) {
  .xlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xlarge-offset-0 {
    margin-left: calc(0% + 2.5rem); }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xlarge-offset-1 {
    margin-left: calc(8.33333% + 2.5rem); }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xlarge-offset-2 {
    margin-left: calc(16.66667% + 2.5rem); }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xlarge-offset-3 {
    margin-left: calc(25% + 2.5rem); }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xlarge-offset-4 {
    margin-left: calc(33.33333% + 2.5rem); }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xlarge-offset-5 {
    margin-left: calc(41.66667% + 2.5rem); }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xlarge-offset-6 {
    margin-left: calc(50% + 2.5rem); }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xlarge-offset-7 {
    margin-left: calc(58.33333% + 2.5rem); }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xlarge-offset-8 {
    margin-left: calc(66.66667% + 2.5rem); }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xlarge-offset-9 {
    margin-left: calc(75% + 2.5rem); }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xlarge-offset-10 {
    margin-left: calc(83.33333% + 2.5rem); }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xlarge-offset-11 {
    margin-left: calc(91.66667% + 2.5rem); } }

@media screen and (min-width: 90em) {
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxlarge-offset-0 {
    margin-left: calc(0% + 2.5rem); }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxlarge-offset-1 {
    margin-left: calc(8.33333% + 2.5rem); }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxlarge-offset-2 {
    margin-left: calc(16.66667% + 2.5rem); }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xxlarge-offset-3 {
    margin-left: calc(25% + 2.5rem); }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxlarge-offset-4 {
    margin-left: calc(33.33333% + 2.5rem); }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxlarge-offset-5 {
    margin-left: calc(41.66667% + 2.5rem); }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xxlarge-offset-6 {
    margin-left: calc(50% + 2.5rem); }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxlarge-offset-7 {
    margin-left: calc(58.33333% + 2.5rem); }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxlarge-offset-8 {
    margin-left: calc(66.66667% + 2.5rem); }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xxlarge-offset-9 {
    margin-left: calc(75% + 2.5rem); }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxlarge-offset-10 {
    margin-left: calc(83.33333% + 2.5rem); }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxlarge-offset-11 {
    margin-left: calc(91.66667% + 2.5rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 75em) {
    .grid-y > .xlarge-shrink, .grid-y > .xlarge-full, .grid-y > .xlarge-1, .grid-y > .xlarge-2, .grid-y > .xlarge-3, .grid-y > .xlarge-4, .grid-y > .xlarge-5, .grid-y > .xlarge-6, .grid-y > .xlarge-7, .grid-y > .xlarge-8, .grid-y > .xlarge-9, .grid-y > .xlarge-10, .grid-y > .xlarge-11, .grid-y > .xlarge-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 90em) {
    .grid-y > .xxlarge-shrink, .grid-y > .xxlarge-full, .grid-y > .xxlarge-1, .grid-y > .xxlarge-2, .grid-y > .xxlarge-3, .grid-y > .xxlarge-4, .grid-y > .xxlarge-5, .grid-y > .xxlarge-6, .grid-y > .xxlarge-7, .grid-y > .xxlarge-8, .grid-y > .xxlarge-9, .grid-y > .xxlarge-10, .grid-y > .xxlarge-11, .grid-y > .xxlarge-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }
  @media screen and (min-width: 75em) {
    .grid-y > .xlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xlarge-shrink {
      height: auto; }
    .grid-y > .xlarge-1 {
      height: 8.33333%; }
    .grid-y > .xlarge-2 {
      height: 16.66667%; }
    .grid-y > .xlarge-3 {
      height: 25%; }
    .grid-y > .xlarge-4 {
      height: 33.33333%; }
    .grid-y > .xlarge-5 {
      height: 41.66667%; }
    .grid-y > .xlarge-6 {
      height: 50%; }
    .grid-y > .xlarge-7 {
      height: 58.33333%; }
    .grid-y > .xlarge-8 {
      height: 66.66667%; }
    .grid-y > .xlarge-9 {
      height: 75%; }
    .grid-y > .xlarge-10 {
      height: 83.33333%; }
    .grid-y > .xlarge-11 {
      height: 91.66667%; }
    .grid-y > .xlarge-12 {
      height: 100%; } }
  @media screen and (min-width: 90em) {
    .grid-y > .xxlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xxlarge-shrink {
      height: auto; }
    .grid-y > .xxlarge-1 {
      height: 8.33333%; }
    .grid-y > .xxlarge-2 {
      height: 16.66667%; }
    .grid-y > .xxlarge-3 {
      height: 25%; }
    .grid-y > .xxlarge-4 {
      height: 33.33333%; }
    .grid-y > .xxlarge-5 {
      height: 41.66667%; }
    .grid-y > .xxlarge-6 {
      height: 50%; }
    .grid-y > .xxlarge-7 {
      height: 58.33333%; }
    .grid-y > .xxlarge-8 {
      height: 66.66667%; }
    .grid-y > .xxlarge-9 {
      height: 75%; }
    .grid-y > .xxlarge-10 {
      height: 83.33333%; }
    .grid-y > .xxlarge-11 {
      height: 91.66667%; }
    .grid-y > .xxlarge-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -1.875rem;
      margin-bottom: -1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -2.5rem;
      margin-bottom: -2.5rem; } }

.grid-padding-y > .cell {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-y > .cell {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }

.grid-margin-y {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -1.875rem;
      margin-bottom: -1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y {
      margin-top: -2.5rem;
      margin-bottom: -2.5rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 2.5rem);
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 3.75rem);
      margin-top: 1.875rem;
      margin-bottom: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .cell {
      height: calc(100% - 5rem);
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 2.5rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 2.5rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 2.5rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 2.5rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 2.5rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 2.5rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 2.5rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 2.5rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 2.5rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 2.5rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 2.5rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 2.5rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 3.75rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 3.75rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 3.75rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 3.75rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 3.75rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 3.75rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 3.75rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 3.75rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 3.75rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 3.75rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 3.75rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 3.75rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 3.75rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 3.75rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 3.75rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 3.75rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 3.75rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 3.75rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 3.75rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 3.75rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 3.75rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 3.75rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 3.75rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 3.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 5rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 5rem); }
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 5rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 5rem); } }
  @media screen and (min-width: 90em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 5rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 75em) {
  .xlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xlarge-grid-frame {
    width: 100%; }
  .xlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 90em) {
  .xxlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xxlarge-grid-frame {
    width: 100%; }
  .xxlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xxlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xxlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xxlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 75em) {
  .grid-y.xlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 90em) {
  .grid-y.xxlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

@media screen and (min-width: 75em) {
  .cell .grid-y.xlarge-grid-frame {
    height: 100%; } }

@media screen and (min-width: 90em) {
  .cell .grid-y.xxlarge-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -1.875rem;
      margin-bottom: -1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y {
      margin-top: -2.5rem;
      margin-bottom: -2.5rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 2.5rem);
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 3.75rem);
      margin-top: 1.875rem;
      margin-bottom: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .cell {
      height: calc(100% - 5rem);
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 2.5rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 2.5rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 2.5rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 2.5rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 2.5rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 2.5rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 2.5rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 2.5rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 2.5rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 2.5rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 2.5rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 2.5rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 3.75rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 3.75rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 3.75rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 3.75rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 3.75rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 3.75rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 3.75rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 3.75rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 3.75rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 3.75rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 3.75rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 3.75rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 3.75rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 3.75rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 3.75rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 3.75rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 3.75rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 3.75rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 3.75rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 3.75rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 3.75rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 3.75rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 3.75rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 3.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 5rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 5rem); }
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 5rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 5rem); } }
  @media screen and (min-width: 90em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 5rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 5rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 5rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 5rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 5rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 5rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 5rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 5rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 5rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 5rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 5rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 5rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 2.5rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 3.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 5rem); } }
  @media screen and (min-width: 75em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 5rem); } }
  @media screen and (min-width: 90em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 5rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 3.75rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 5rem); } }

@media screen and (min-width: 75em) {
  .grid-margin-y.xlarge-grid-frame {
    height: calc(100vh + 5rem); } }

@media screen and (min-width: 90em) {
  .grid-margin-y.xxlarge-grid-frame {
    height: calc(100vh + 5rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "museo-sans-rounded", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.75rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #E61853;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #c61547; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 84.375rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: "museo-sans-rounded", sans-serif;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: "museo-sans-rounded", sans-serif;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 4rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #E61853;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #c41447;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #E61853;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #b81342;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #1f202c;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #191a23;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #E61853;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #E61853;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #1f202c;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #E61853;
    color: #E61853; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #730c2a;
      color: #730c2a; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #E61853;
        color: #E61853; }
    .button.hollow.primary {
      border: 1px solid #E61853;
      color: #E61853; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #730c2a;
        color: #730c2a; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #E61853;
          color: #E61853; }
    .button.hollow.secondary {
      border: 1px solid #1f202c;
      color: #1f202c; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #101016;
        color: #101016; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #1f202c;
          color: #1f202c; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #E61853;
    color: #E61853; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #730c2a;
      color: #730c2a; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #E61853;
        color: #E61853; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #E61853;
      color: #E61853; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #730c2a;
        color: #730c2a; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #E61853;
          color: #E61853; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #1f202c;
      color: #1f202c; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #101016;
        color: #101016; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #1f202c;
          color: #1f202c; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #E61853; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #E61853; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #1f202c; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #fbdce5;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #d9dae4;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 75em) {
    .menu.xlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xlarge-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 90em) {
    .menu.xxlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xxlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xxlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xxlarge-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #E61853;
    color: #fefefe; }
  .menu .active > a {
    background: #E61853;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 75em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 90em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xxlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xxlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xxlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

@media screen and (min-width: 75em) {
  .off-canvas.in-canvas-for-xlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xlarge.position-left, .off-canvas.in-canvas-for-xlarge.position-right, .off-canvas.in-canvas-for-xlarge.position-top, .off-canvas.in-canvas-for-xlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xlarge .close-button {
      display: none; } }

@media screen and (min-width: 90em) {
  .off-canvas.in-canvas-for-xxlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xxlarge.position-left, .off-canvas.in-canvas-for-xxlarge.position-right, .off-canvas.in-canvas-for-xxlarge.position-top, .off-canvas.in-canvas-for-xxlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xxlarge .close-button {
      display: none; } }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 84.375rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 84.375rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 84.375rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 84.375rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: transparent; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 89.9375em) {
    .top-bar.stacked-for-xlarge {
      flex-wrap: wrap; }
      .top-bar.stacked-for-xlarge .top-bar-left,
      .top-bar.stacked-for-xlarge .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  .top-bar.stacked-for-xxlarge {
    flex-wrap: wrap; }
    .top-bar.stacked-for-xxlarge .top-bar-left,
    .top-bar.stacked-for-xxlarge .top-bar-right {
      flex: 0 0 100%;
      max-width: 100%; }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 90em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/
body {
  background: #181824; }

#nav-container {
  background: #181824;
  background-size: cover;
  background-color: #E61853; }
  @media print, screen and (min-width: 40em) {
    #nav-container {
      background: #181824;
      background-size: cover; } }

#container {
  /*
	&:before {
	  content: "";
	  position: absolute;
	  top: 0; 
	  left: 0;
	  right: 0;
	  margin: 0 auto;
	  width: $global-width;
	  height: 6800px;
	  opacity: .4; 
	  z-index: -1;
	  background:  url(../img/bg-cover.png);
	}
	*/ }

h1.pink, h2.pink, h3.pink, p.pink {
  color: #E61853; }

.white {
  color: #fefefe !important; }

#vid-reveal {
  padding-top: 50px; }

.mpu-banner {
  max-width: 100%;
  min-height: 250px;
  z-index: 20;
  position: relative;
  border: solid 1px gray; }

.mpu-container {
  margin-bottom: 2.60417rem;
  text-align: center; }
  .mpu-container p {
    text-align: center !important; }

.sticky-container {
  z-index: 99999; }

fieldset {
  max-width: 100% !important; }

.hs-input {
  width: 100% !important; }

ul {
  list-style-type: none !important; }

label > [type='checkbox'],
label > [type='radio'] {
  max-width: 20px !important; }

.hs-richtext {
  text-align: left !important;
  font-size: 0.8rem !important; }
  .hs-richtext p {
    text-align: left !important;
    font-size: 0.8rem; }

#section7 form [type='submit'], #section7 form [type='button'] {
  margin-top: 2rem !important;
  width: 100% !important;
  cursor: pointer; }

#section7 form a {
  color: #fefefe;
  text-decoration: underline; }

.printHide {
  display: block; }

.printOnly {
  display: none; }

.nav-grad {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#084a69+0,001b3c+99 */
  background: #084a69;
  /* Old browsers */
  background: -moz-linear-gradient(top, #084a69 0%, #2daae1 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #084a69 0%, #2daae1 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #084a69 0%, #2daae1 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#084a69', endColorstr='#2daae1',GradientType=0 );
  /* IE6-9 */ }

.blue-grad {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6dc4de+0,41a2be+100 */
  background: #6dc4de;
  /* Old browsers */
  background: -moz-linear-gradient(top, #6dc4de 0%, #41a2be 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #6dc4de 0%, #41a2be 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #6dc4de 0%, #41a2be 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6dc4de', endColorstr='#41a2be',GradientType=0 );
  /* IE6-9 */ }

nav a {
  color: #fefefe; }

nav .dropdown.menu a {
  padding: 0.7rem 11px; }

nav .dropdown.menu li:last-child a {
  padding-right: 0; }

.off-canvas li {
  width: 100%; }

nav.top-bar {
  margin-top: 0;
  padding: 0.5rem 24px;
  padding: 20px 40px;
  transition: background-color 3s ease;
  padding: 24px; }
  nav.top-bar img {
    width: 100%;
    transition: all 0.5s ease;
    max-width: 240px; }
    nav.top-bar img.small {
      max-width: 190px; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: landscape) {
  nav.top-bar img {
    max-width: 240px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) and (orientation: portrait) {
  nav.top-bar img {
    max-width: 240px; } }
  @media screen and (min-width: 1020px) and (max-width: 1120px) {
    nav.top-bar img {
      max-width: 240px; } }
  nav.top-bar.is-stuck {
    background-color: rgba(31, 32, 44, 0.8);
    padding: 20px 40px; }

nav.title-bar {
  display: none;
  padding: 0.5rem;
  background: #181824;
  height: 95px; }
  nav.title-bar .title-bar-title img {
    width: 200px;
    display: block;
    position: absolute;
    left: 24px;
    top: 24px; }
    @media screen and (max-width: 39.9375em) {
      nav.title-bar .title-bar-title img {
        left: 0;
        top: 24px;
        margin: 0 auto;
        right: 0; } }

.top-bar .top-bar-right {
  width: 350px; }

footer {
  position: relative;
  padding: 20% 4%;
  color: #fefefe; }
  @media print, screen and (min-width: 40em) {
    footer {
      padding: 4% 4% 8% 4%; } }
  footer img {
    max-width: 242px;
    margin-bottom: 20px; }
  footer p {
    font-size: 13px; }
  footer .privacy {
    text-align: center; }

section {
  position: relative;
  padding: 20% 4%; }
  @media print, screen and (min-width: 40em) {
    section {
      padding: 4% 4% 8% 4%; } }
  section h1,
  section h2,
  section h3,
  section h4,
  section h5,
  section h6,
  section pre,
  section p,
  section blockquote,
  section th,
  section td {
    text-align: center; }
  @media print, screen and (min-width: 40em) {
    section h1,
    section h2,
    section h3,
    section h4,
    section h5,
    section h6,
    section pre,
    section p,
    section blockquote,
    section th,
    section td {
      text-align: center; } }
  section img {
    margin: 0 auto;
    display: block; }

section.narrow {
  padding: 8% 4%;
  padding: 20% 4%; }
  @media print, screen and (min-width: 40em) {
    section.narrow {
      padding: 8% 26%; } }
  section.narrow h1,
  section.narrow h2,
  section.narrow h3,
  section.narrow h4,
  section.narrow h5,
  section.narrow h6,
  section.narrow pre,
  section.narrow form,
  section.narrow p,
  section.narrow blockquote,
  section.narrow th,
  section.narrow td {
    text-align: center; }
  section.narrow img {
    margin: 0 auto;
    display: block; }

section.section-divider {
  max-width: 84.375rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%; }
  section.section-divider::before, section.section-divider::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  section.section-divider::after {
    clear: both; }
  section.section-divider hr {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    border-bottom: 5px solid #e6e6e6;
    box-sizing: border-box;
    margin: 0; }
    @media print, screen and (min-width: 40em) {
      section.section-divider hr {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    section.section-divider hr:last-child:not(:first-child) {
      float: right; }

section.pink {
  background-color: #E61853;
  color: #fefefe; }

section.opacity {
  opacity: 0.7; }

#section1 {
  padding: 30px 4% 8% 4%; }
  @media print, screen and (min-width: 40em) {
    #section1 {
      padding: 130px 4% 8% 4%; } }
  #section1 h1 {
    color: #fefefe;
    /*
    &:after{
      content: "";
      position: absolute;
      background-color: get-color(primary);

      top: 65px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 84px;
      height: 2px;

      @include breakpoint(medium) {
        top: 208px;
        width: 179px;
        height: 3px;
      }
      @include breakpoint(large) {
        top: 208px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 154px;
        height: 4px;
      }
      
    }
    */ }
    @media print, screen and (min-width: 40em) {
      #section1 h1 {
        margin-bottom: 50px;
        font-size: 62px; } }
  #section1 h2 {
    color: #E61853;
    padding: 0 40px; }
    @media print, screen and (min-width: 40em) {
      #section1 h2 {
        line-height: 40px;
        font-size: 40px; } }
  #section1 form {
    margin-top: 29px; }
    #section1 form input {
      max-width: 393px;
      margin: 0 auto;
      height: 50px;
      background: transparent;
      padding: 16px;
      color: #fefefe; }
      #section1 form input::placeholder {
        color: #fefefe; }
    #section1 form button, #section1 form a {
      border: 1px solid #fefefe;
      margin: 0 auto;
      padding: 16px;
      display: block;
      max-width: 380px; }
      @media print, screen and (min-width: 40em) {
        #section1 form button, #section1 form a {
          font-size: 18px; } }

#section2 {
  background: url("../img/top-bg-2.png") no-repeat top center scroll;
  background-size: contain;
  padding: 85px 4% 6% 4%; }
  @media print, screen and (min-width: 40em) {
    #section2 {
      padding: 148px 4% 10% 4%; } }
  #section2 h2 {
    font-size: 32px;
    margin-right: 5px;
    margin-left: 5px;
    color: #fefefe;
    margin: 1rem 1rem 2.5rem 1rem; }
  #section2 p {
    color: #fefefe;
    margin-bottom: 25px; }
    @media print, screen and (min-width: 40em) {
      #section2 p {
        text-align: left; } }
  #section2 #vid-container {
    position: relative;
    margin-left: 10px;
    margin-right: 10px; }
    @media print, screen and (min-width: 40em) {
      #section2 #vid-container {
        margin-left: 30px;
        margin-right: 30px; } }
    #section2 #vid-container #vid-overlay {
      background-color: #E61853;
      position: relative;
      padding: 29px;
      margin-top: 30px;
      margin-bottom: 30px; }
      @media print, screen and (min-width: 40em) {
        #section2 #vid-container #vid-overlay {
          position: absolute;
          bottom: -79px;
          right: -34px;
          width: 377px;
          height: 210px;
          padding: 29px; } }
      #section2 #vid-container #vid-overlay:before {
        position: absolute;
        right: -1px;
        top: -1px;
        content: '';
        border-top: 30px solid #1f202c;
        border-left: 30px solid transparent; }
      #section2 #vid-container #vid-overlay:after {
        position: absolute;
        left: -1px;
        bottom: -1px;
        content: '';
        border-top: 30px solid transparent;
        border-left: 30px solid #1f202c; }
      #section2 #vid-container #vid-overlay h3 {
        color: white;
        text-align: left;
        line-height: 33px; }
      #section2 #vid-container #vid-overlay p {
        text-align: left;
        margin-bottom: 0; }

#section3 h2 {
  font-size: 32px;
  margin-right: 5px;
  margin-left: 5px;
  color: #fefefe;
  margin-bottom: 9px; }

#section3 p {
  color: #fefefe;
  font-size: 19px;
  margin: 0 0 26px; }

@media print, screen and (min-width: 40em) {
  #section3 .right-panel {
    margin-top: 25px; }
    #section3 .right-panel h2 {
      text-align: left;
      margin-bottom: 24px; }
    #section3 .right-panel p {
      text-align: left;
      margin-left: 5px;
      margin-right: 5px; } }

#section4 {
  padding: 35px; }
  #section4 .grid-container {
    padding-right: 0;
    padding-left: 0; }
  @media print, screen and (min-width: 64em) {
    #section4 {
      padding: 138px 160px 85px; } }
  @media print, screen and (min-width: 40em) {
    #section4 .cell:nth-child(odd) {
      padding-right: 13px; }
    #section4 .cell:nth-child(even) {
      padding-left: 13px; } }
  #section4 .card {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 160px;
    position: relative;
    min-height: 230px;
    background-color: transparent;
    border: 1px solid #E61853; }
    @media print, screen and (min-width: 40em) {
      #section4 .card {
        padding-left: 30px;
        padding-right: 30px; } }
    @media print, screen and (min-width: 64em) {
      #section4 .card {
        padding-left: 150px;
        padding-right: 30px;
        padding-top: 20px; } }
    #section4 .card img {
      left: 0;
      position: absolute;
      top: 34px;
      width: 112px;
      overflow: hidden;
      border-radius: 50%;
      border: 3px solid #E61853; }
      @media screen and (max-width: 63.9375em) {
        #section4 .card img {
          right: 0; } }
      @media print, screen and (min-width: 64em) {
        #section4 .card img {
          left: 34px; } }
    @media print, screen and (min-width: 40em) {
      #section4 .card h5, #section4 .card p {
        text-align: left; } }
    #section4 .card h5 {
      color: #fefefe; }
    #section4 .card p {
      color: #fefefe;
      font-size: 16px;
      font-weight: bold; }
  #section4 .button {
    margin: 36px auto 0;
    display: block;
    max-width: 300px; }

#section5 {
  background-color: #fefefe; }
  @media print, screen and (min-width: 64em) {
    #section5 {
      padding: 115px 160px; } }

@media screen and (min-width: 40em) and (orientation: landscape) {
  #section5 {
    padding: 115px 50px; } }
  @media screen and (min-width: 75em) {
    #section5 {
      padding: 115px 160px; } }
  #section5 #experts {
    max-width: 420px;
    float: left;
    width: 100%;
    margin-top: 7px; }
  #section5 p {
    text-align: left; }
  #section5 #members-grid {
    margin-top: 48px;
    padding: 0 33px; }
    #section5 #members-grid .cell {
      padding-right: 1.5rem;
      padding-left: 1.5rem; }
    #section5 #members-grid .card {
      background-color: transparent;
      border: none; }
      #section5 #members-grid .card h5 {
        margin-top: 18px;
        font-size: 18px;
        margin-bottom: 0; }
      #section5 #members-grid .card img {
        width: 112px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        border: 7px solid #E61853; }
      #section5 #members-grid .card p {
        text-align: center; }
  #section5 .grid-seperator h5 {
    color: #E61853;
    position: relative;
    z-index: 1;
    margin-top: 20px; }
    #section5 .grid-seperator h5:before {
      content: "";
      margin: 0 auto;
      /* this centers the line to the full width specified */
      position: absolute;
      /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 95%;
      z-index: -1; }
    #section5 .grid-seperator h5:after {
      border-bottom: 2px solid #cacaca;
      content: "";
      margin: 0 auto;
      /* this centers the line to the full width specified */
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      width: 95%;
      z-index: -1; }
    #section5 .grid-seperator h5 span {
      /* to hide the lines from behind the text, you have to set the background color the same as the container */
      background: #fff;
      padding: 0 15px; }
  #section5 #member-icon {
    padding: 41px 0 1px;
    background: url("../img/members-bg.png") no-repeat top center scroll;
    background-size: cover;
    margin-top: 41px; }
    #section5 #member-icon h2 {
      font-size: 31px;
      color: #fefefe; }
    #section5 #member-icon p {
      text-align: center;
      color: white; }
    #section5 #member-icon .button {
      margin: 40px auto;
      display: block;
      width: 230px; }

#section6 {
  padding: 4% 4% 4% 4%; }
  #section6 h2, #section6 p {
    color: #fefefe; }

#section7 {
  padding: 97px 4%; }
  #section7 form {
    margin-top: 46px; }
    #section7 form label {
      color: #fefefe; }
    #section7 form input, #section7 form select, #section7 form textarea {
      min-height: 50px;
      background: transparent;
      padding: 14px;
      color: #fefefe; }
      #section7 form input::placeholder, #section7 form select::placeholder, #section7 form textarea::placeholder {
        color: #fefefe; }
    #section7 form textarea {
      min-height: 185px; }
    #section7 form button {
      border: 1px solid #fefefe;
      margin: 0 auto;
      padding: 16px;
      display: block;
      font-size: 1rem; }
      #section7 form button.solid.blue {
        border: none;
        background-color: #1f202c;
        float: right;
        max-width: 250px;
        margin-top: 25px; }

/**************************************** COOKIE  ****************************************/
.cc-window {
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  transition: opacity 1s ease; }

.cc-window.cc-invisible {
  opacity: 0; }

/* only animate ifhas class 'cc-animate' */
.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -moz-transition: transform 1s ease;
  -ms-transition: transform 1s ease;
  -o-transition: transform 1s ease;
  transition: transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-top {
  transform: translateY(0); }

.cc-animate.cc-revoke.cc-active.cc-bottom {
  transform: translateY(0); }

.cc-revoke:hover {
  transform: translateY(0); }

.cc-grower {
  /* Initially we don't want any height, and we want the contents to be hidden */
  max-height: 0;
  overflow: hidden;
  /* Set our transitions up. */
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s; }

/* the popup window */
.cc-window, .cc-revoke {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  /* exclude padding when dealing with width */
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  /* by setting the base font here, we can size the rest of the popup using CSS `em` */
  line-height: 1.5em;
  display: flex;
  flex-wrap: nowrap;
  /* the following are random unjustified styles - just because - should probably be removed */
  z-index: 9999; }

.cc-window.cc-static {
  position: static; }

/* 2 basic types of window - floating / banner */
.cc-window.cc-floating {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  /* exclude padding when dealing with width */
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  /* by setting the base font here, we can size the rest of the popup using CSS `em` */
  line-height: 1.5em;
  display: flex;
  flex-wrap: nowrap;
  /* the following are random unjustified styles - just because - should probably be removed */
  z-index: 9999;
  padding: 2em;
  max-width: 24em;
  /* 1em == 16px therefore 24em == 384px */
  flex-direction: column;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); }

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  flex-direction: row; }

.cc-revoke {
  padding: .5em; }

.cc-revoke:hover {
  text-decoration: underline; }

.cc-header {
  font-size: 18px;
  font-weight: bold; }

/* clickable things */
.cc-btn, .cc-link, .cc-close, .cc-revoke {
  cursor: pointer; }

.cc-link {
  opacity: .8;
  display: inline-block;
  padding: .2em;
  text-decoration: underline; }

.cc-link:hover {
  opacity: 1; }

.cc-link:active, .cc-link:visited {
  color: initial; }

.cc-btn {
  display: block;
  padding: .4em .8em;
  font-size: 0.9em;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap; }

.cc-banner .cc-btn:last-child {
  min-width: 140px; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }

.cc-highlight .cc-btn:first-child:hover,
.cc-highlight .cc-btn:first-child:focus {
  background-color: transparent;
  text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
  font-size: 1.6em;
  opacity: .9;
  /* seeing as this contains text and not an image, the element taller than it is wide (because it is text) */
  /*  - we want it to be a square, because it's acting as an icon */
  /*  - setting the line height normalises the height */
  line-height: .75; }

.cc-close:hover,
.cc-close:focus {
  opacity: 1; }

/* This file should contain CSS that modifies the popup layout. */
/* By layout, we mean the physical position of the elements on the popup window, and the margin / padding around those elements. */
.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

/**************************************** FLOATING ****************************************/
/* these classes position the floating element */
.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

/* links that are direct decendants should be displayed as block */
.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto; }

/**************************************** BANNER ****************************************/
.cc-window.cc-banner {
  align-items: center; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  flex: 1; }

/* COMPLIANCE BOX */
.cc-compliance {
  display: flex;
  align-items: center;
  align-content: space-between; }

.cc-compliance > .cc-btn {
  flex: 1; }

.cc-btn + .cc-btn {
  margin-left: .5em; }

@media print {
  .cc-window, .cc-revoke {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

/* dimensions for 'iPhone6 Plus' and lower */
@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner, .cc-window.cc-right, .cc-window.cc-left {
    left: 0;
    right: 0; }
  .cc-window.cc-banner {
    flex-direction: column; }
  .cc-window.cc-banner .cc-compliance {
    flex: 1; }
  .cc-window.cc-floating {
    max-width: 500px;
    font-size: 0.8rem; }
  .cc-window .cc-message {
    margin-bottom: 1em; }
  .cc-window.cc-banner {
    align-items: unset; } }

/* iPhone 6 */
/* iPhone 6 plus */
/* iPhone 5 & 5S */
/* iPhone 2G - 4S */
/* Edgeless */
.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em;
  margin-bottom: 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

/* Classic */
.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  flex: none; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

/* uncss:ignore start */
.primary {
  color: #E61853; }

#SkillsHive-title #animation_container, #SkillsHive-title canvas {
  max-width: 80%;
  width: 80% !important;
  position: relative !important;
  height: auto !important;
  margin: 0 auto; }

#animation_container {
  z-index: 100; }

#skills-cone-container {
  background: url(../img/Header-image4.png) no-repeat bottom center scroll;
  background-size: contain;
  padding: 0% 4% 8% 4%;
  /*background-color:$secondary-color;*/
  color: #fefefe; }
  #skills-cone-container:after {
    height: 100%;
    width: 100%;
    content: '';
    bottom: 0;
    background-image: url(../img/female-designer.png);
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom right;
    left: 0;
    opacity: 0.3;
    z-index: 0; }
  #skills-cone-container h1, #skills-cone-container h2, #skills-cone-container h3, #skills-cone-container h4, #skills-cone-container h5, #skills-cone-container h6, #skills-cone-container a {
    color: #fefefe; }
    #skills-cone-container h1 span, #skills-cone-container h2 span, #skills-cone-container h3 span, #skills-cone-container h4 span, #skills-cone-container h5 span, #skills-cone-container h6 span, #skills-cone-container a span {
      color: #E61853; }
    #skills-cone-container h1 .info-icon, #skills-cone-container h2 .info-icon, #skills-cone-container h3 .info-icon, #skills-cone-container h4 .info-icon, #skills-cone-container h5 .info-icon, #skills-cone-container h6 .info-icon, #skills-cone-container a .info-icon {
      border-radius: 0%;
      font-size: 1.5rem;
      width: 26px;
      height: 27px;
      padding-left: 0;
      padding-top: 2px;
      vertical-align: super;
      cursor: pointer;
      color: #E61853;
      margin-left: 10px;
      z-index: 10;
      position: absolute; }
  #skills-cone-container a {
    text-decoration: underline; }
    #skills-cone-container a span {
      text-decoration: none;
      color: #fefefe;
      display: inline-block;
      padding-right: 5px; }
  #skills-cone-container #animation_container, #skills-cone-container canvas {
    max-width: 100%;
    width: 100% !important;
    position: relative !important;
    height: auto !important;
    margin: 0 auto; }

#digital-skills-tooltip, #createReport, #digital-skills-contact {
  max-width: 800px;
  background: #fefefe;
  border-color: #85858b;
  border-radius: 25px;
  padding: 2rem; }
  #digital-skills-tooltip .button, #createReport .button, #digital-skills-contact .button {
    margin: 0 0 0; }
  #digital-skills-tooltip img, #createReport img, #digital-skills-contact img {
    padding-left: 20px; }
  #digital-skills-tooltip .close-button, #createReport .close-button, #digital-skills-contact .close-button {
    color: #aaa; }
  #digital-skills-tooltip [type='text'], #digital-skills-tooltip [type='password'], #digital-skills-tooltip [type='date'], #digital-skills-tooltip [type='datetime'], #digital-skills-tooltip [type='datetime-local'], #digital-skills-tooltip [type='month'], #digital-skills-tooltip [type='week'], #digital-skills-tooltip [type='email'], #digital-skills-tooltip [type='number'], #digital-skills-tooltip [type='search'], #digital-skills-tooltip [type='tel'], #digital-skills-tooltip [type='time'], #digital-skills-tooltip [type='url'], #digital-skills-tooltip [type='color'], #digital-skills-tooltip textarea, #createReport [type='text'], #createReport [type='password'], #createReport [type='date'], #createReport [type='datetime'], #createReport [type='datetime-local'], #createReport [type='month'], #createReport [type='week'], #createReport [type='email'], #createReport [type='number'], #createReport [type='search'], #createReport [type='tel'], #createReport [type='time'], #createReport [type='url'], #createReport [type='color'], #createReport textarea, #digital-skills-contact [type='text'], #digital-skills-contact [type='password'], #digital-skills-contact [type='date'], #digital-skills-contact [type='datetime'], #digital-skills-contact [type='datetime-local'], #digital-skills-contact [type='month'], #digital-skills-contact [type='week'], #digital-skills-contact [type='email'], #digital-skills-contact [type='number'], #digital-skills-contact [type='search'], #digital-skills-contact [type='tel'], #digital-skills-contact [type='time'], #digital-skills-contact [type='url'], #digital-skills-contact [type='color'], #digital-skills-contact textarea {
    border-radius: 10px;
    border: 1px solid #aaa;
    box-shadow: none; }

#digital-skills-tooltip {
  background: #1f202c;
  color: #fefefe; }

#digital-skills-contact input[type='submit'] {
  display: block;
  width: 100%;
  vertical-align: middle;
  margin: 0 0 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #E61853;
  color: #fefefe;
  margin-top: 2rem; }

.text-align-left {
  text-align: left; }
  .text-align-left h1, .text-align-left h2, .text-align-left h3, .text-align-left h4, .text-align-left h5, .text-align-left h6, .text-align-left p {
    text-align: left; }

.hc_intro .click-icon {
  max-width: 100px;
  margin-bottom: 50px; }

.digital-skills-tooltip {
  border: 1px solid #fefefe;
  border-radius: 0;
  padding: 20px;
  position: absolute;
  max-width: 500px;
  z-index: 100;
  background: #1f202c;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s; }
  .digital-skills-tooltip.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s, opacity 0.3s; }
  .digital-skills-tooltip:after {
    position: absolute;
    top: 0px;
    right: 1rem;
    content: '+';
    transform: rotate(45deg);
    font-size: 2rem;
    cursor: pointer; }
  .digital-skills-tooltip .button {
    color: #fefefe;
    border: 1px solid #fefefe;
    width: 100%; }

ul.skills-block {
  color: #fefefe;
  font-size: 0.625rem;
  padding: 0px;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-left: 0; }
  ul.skills-block li {
    display: inline-block;
    background-color: #8b8b91;
    padding: 3px 15px 10px 10px;
    border-radius: 0px 4px 4px 0px;
    margin: 0.5rem 9px 0.1rem 15px;
    text-transform: uppercase;
    position: relative;
    max-height: 26px;
    font-size: 0.9rem;
    width: fit-content;
    line-height: 23px; }
    @media print, screen and (min-width: 64em) {
      ul.skills-block li {
        display: block; } }
    ul.skills-block li:before {
      width: 15px;
      content: " ";
      position: absolute;
      left: -14px;
      top: 0;
      display: inline-block;
      height: 26px;
      background-image: url(../img/skills-report/tag-pointer.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }

#skills-content-container {
  background-color: #fefefe;
  position: relative; }
  #skills-content-container .skill-content-block {
    padding-top: 1.5rem; }
    #skills-content-container .skill-content-block h1, #skills-content-container .skill-content-block h2, #skills-content-container .skill-content-block h3, #skills-content-container .skill-content-block h4, #skills-content-container .skill-content-block h5, #skills-content-container .skill-content-block h6, #skills-content-container .skill-content-block p {
      text-align: left; }
    #skills-content-container .skill-content-block p {
      min-height: 160px;
      max-height: 160px; }
    #skills-content-container .skill-content-block h3 img {
      max-width: 28px; }
    #skills-content-container .skill-content-block .sub-cat {
      color: #fefefe;
      font-size: 0.625rem;
      padding: 0px;
      border-bottom: 1px solid #BBB9BD;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      min-height: 60px; }
      #skills-content-container .skill-content-block .sub-cat span {
        background-color: #BBB9BD;
        display: inline-block;
        padding: 5px 10px 3px;
        border-radius: 0px 4px 4px 0px;
        margin: 0.1rem 9px 0.1rem 15px;
        text-transform: uppercase;
        position: relative;
        max-height: 23px;
        background-color: #8b8b91; }
        #skills-content-container .skill-content-block .sub-cat span:before {
          width: 15px;
          content: " ";
          position: absolute;
          left: -14px;
          top: 0;
          display: inline-block;
          height: 23px;
          background-image: url(../img/skills-report/tag-pointer.svg);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat; }
  #skills-content-container .content-bar, #skills-content-container .sub-content-bar {
    height: 10px;
    width: 100%;
    margin-bottom: 0.75rem;
    background-color: #BBB9BD; }
  #skills-content-container .sub-content-bar {
    width: 75%; }
  #skills-content-container .additional-info-block {
    cursor: pointer; }
    #skills-content-container .additional-info-block .button {
      opacity: 1;
      background-color: #1f202c;
      width: 100%;
      margin-top: 10px;
      transition: opacity 0.3s; }
      #skills-content-container .additional-info-block .button i {
        margin-right: 5px; }
      #skills-content-container .additional-info-block .button:before {
        background-color: #e6e6e6; }
      #skills-content-container .additional-info-block .button:hover {
        color: #1f202c !important; }
    #skills-content-container .additional-info-block:hover .button {
      opacity: 1;
      transition: opacity 0.3s; }

#skill-cone-signup {
  background: url("/assets/img/Skills_Comb_report@2x.jpg") no-repeat center center;
  background-size: cover;
  background-color: #fefefe; }
  @media screen and (max-width: 39.9375em) {
    #skill-cone-signup {
      padding-bottom: 80%;
      background-position: -62vw bottom;
      background-size: auto 80vw; } }
  #skill-cone-signup .checklist {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
    #skill-cone-signup .checklist p {
      padding-left: 50px;
      position: relative; }
      #skill-cone-signup .checklist p:before {
        content: '\f058';
        font-family: 'Font Awesome 5 Pro';
        font-weight: bold;
        position: absolute;
        left: 10px;
        top: -5px;
        font-size: 1.875rem;
        color: #BBB9BD; }

.skills-cone-button {
  width: 100%;
  background-color: #00dd8b;
  font-size: 1.25rem;
  border-radius: 20px; }
  .skills-cone-button:before {
    background-color: #00dd8b; }
  .skills-cone-button:hover {
    color: #00dd8b !important;
    background-color: #00dd8b; }

#full-report {
  background-color: #fefefe; }

#skills-cone-additional {
  background-color: #1f202c;
  color: #fefefe;
  position: relative; }
  #skills-cone-additional h1, #skills-cone-additional h2, #skills-cone-additional h3, #skills-cone-additional h4, #skills-cone-additional h5, #skills-cone-additional h6, #skills-cone-additional a {
    color: #fefefe; }
  #skills-cone-additional:before {
    background-color: #fefefe;
    height: 50px;
    width: 50px;
    content: '';
    top: -25px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) rotate(45deg); }

#skills-comb-view img {
  max-width: 34.375rem; }

.darkSection {
  color: #fefefe; }
  .darkSection h1 span {
    font-weight: 700; }

#SkillsHive-logo {
  margin-bottom: 3.125rem; }
  #SkillsHive-logo img {
    max-width: 18.75rem; }

#SkillsHive-title {
  background: url(/assets/img/Header-image4.jpg) no-repeat bottom center scroll; }
  #SkillsHive-title h1 {
    margin-bottom: 3.125rem; }
  #SkillsHive-title .share {
    margin-top: 3.125rem; }
    #SkillsHive-title .share a {
      color: #fefefe;
      text-decoration: underline; }

#SkillsHive-footer {
  background: url(/assets/img/Header-image4.png) no-repeat bottom center scroll; }

#identifiedGaps-Container {
  background-color: #fefefe; }

.seperator {
  border-bottom: 2px dotted #fefefe;
  margin-bottom: 4%; }

.identifiedGaps-Title {
  font-size: 2.375rem;
  margin-bottom: 6.25rem; }

.skills-report {
  margin-bottom: 3.125rem; }
  .skills-report img {
    max-width: 3.125rem;
    display: inline-block;
    margin-top: -0.875rem;
    margin-right: 0.625rem; }
  .skills-report h4 {
    text-transform: lowercase;
    padding-bottom: 0;
    display: inline-block; }
  .skills-report .skillDescription h4 {
    display: block; }
  .skills-report .skillDescription .button {
    margin: 0 0 1rem 0;
    border-radius: 100px; }
  .skills-report .skillDescription strong {
    color: #E61853; }

#identifiedGaps-Container {
  background: #181824; }
  #identifiedGaps-Container h1, #identifiedGaps-Container h2, #identifiedGaps-Container h3, #identifiedGaps-Container h4, #identifiedGaps-Container h5, #identifiedGaps-Container h6, #identifiedGaps-Container p {
    color: #fefefe; }

#skills-report-block .skillName {
  font-size: 2.5rem;
  text-transform: capitalize; }

#skills-report-block .skillDescription h4 {
  font-size: 1.5rem;
  color: #E61853;
  margin-top: 2rem; }

#skills-report-block .skillDescription p {
  margin-bottom: 2rem; }

#skills-report-block .skillDescription strong {
  color: #E61853; }

#what-next-title {
  margin-bottom: 3.125rem; }

.what-next > div {
  min-height: 8.4375rem; }

.what-next img {
  margin-bottom: 3.125rem;
  max-height: 8.4375rem; }

.what-next .button {
  margin-top: 3.125rem; }

.roundButton {
  border-radius: 0.625rem; }

.skew-hover {
  overflow: hidden;
  position: relative;
  z-index: 2;
  color: #fefefe !important; }
  .skew-hover:before {
    position: absolute;
    z-index: -1;
    background-color: #00dd8b;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
    display: block; }
  .skew-hover:after {
    content: '';
    width: 150%;
    height: 120%;
    display: block;
    background-color: #fefefe;
    position: absolute;
    left: -170%;
    top: 0;
    transform: skewX(20deg);
    z-index: -1;
    transition: left 0.5s; }
  .skew-hover:hover {
    color: #00dd8b !important;
    transition: color 0.5s; }
    .skew-hover:hover:after {
      left: -20%;
      transition: left 0.5s; }

.hollow.skew-hover:before {
  background: transparent; }

.hollow.skew-hover.light {
  color: #E61853 !important; }
  .hollow.skew-hover.light:after {
    background: #E61853; }
  .hollow.skew-hover.light:hover {
    color: #fefefe !important;
    border-color: #E61853; }

.hollow.skew-hover.dark {
  color: #1f202c !important; }

@media print, screen and (min-width: 40em) {
  #reportTitle {
    text-align: left; } }

@media print, screen and (min-width: 40em) {
  #shareBlock #mainShareLink {
    text-align: left !important; } }

#shareBlock ul {
  margin-left: 0rem; }

@media print, screen and (min-width: 40em) {
  .previous-reports {
    margin-top: 2rem; } }

.hc_intro {
  z-index: 499; }

#skillsTitleBlock {
  z-index: 500;
  margin-left: 0rem; }
  @media print, screen and (min-width: 64em) {
    #skillsTitleBlock {
      margin-left: 6rem; } }
  #skillsTitleBlock h2 {
    font-weight: lighter; }
  @media print, screen and (min-width: 40em) {
    #skillsTitleBlock h2 {
      font-size: 2.5rem; } }
  @media screen and (min-width: 75em) {
    #skillsTitleBlock h2 {
      font-size: 3.5rem; } }
  #skillsTitleBlock .strong {
    line-height: 1.8rem;
    font-size: 1.1rem; }
  @media screen and (max-width: 39.9375em) {
    #skillsTitleBlock h2 {
      font-weight: lighter;
      font-size: 2.5rem;
      text-align: center;
      margin-top: 2rem; } }

.smaller {
  font-size: 0.8rem;
  line-height: 1.8rem; }

.close-button {
  color: #fefefe; }
  .close-button:hover {
    color: #E61853; }

#digital-skills-contact {
  margin-top: 0;
  height: 100vh; }
  @media screen and (max-width: 39.9375em) {
    #digital-skills-contact {
      border-radius: 0; } }
  @media print, screen and (min-width: 40em) {
    #digital-skills-contact {
      margin-top: 1vh;
      height: 99vh;
      margin-bottom: 1vh;
      width: 570px; } }

.hubspot-link__container {
  display: none !important; }

.hs-form-iframe {
  height: 80vh; }

fieldset {
  max-width: 100% !important; }

#enquires-icon {
  cursor: pointer; }

footer {
  margin-top: -25px; }

.previous-reports ul li {
  display: inline-flex;
  width: max-content;
  padding: 5px 10px; }

/* uncss:ignore end */
.skill-content-block .skillDescription {
  position: relative;
  max-height: 180px;
  overflow: hidden; }

.skill-content-block .skillDescription:after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+93&0+24,0.9+55,1+93 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.9) 55%, white 93%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.9) 55%, white 93%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.9) 55%, white 93%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
  pointer-events: none;
  /* so the text is still selectable */
  left: 0; }

#skills-content-container .additional-info-block {
  position: relative; }
  #skills-content-container .additional-info-block i.more-info {
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    font-weight: lighter;
    position: absolute;
    bottom: 40%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    font-size: 30px;
    color: #ccc; }
  #skills-content-container .additional-info-block .button {
    background-color: #e6e6e6 !important;
    max-width: 100%;
    border-radius: 15px;
    margin: 1rem auto 0;
    display: block;
    color: #8a8a8a !important;
    border: 1px solid #e6e6e6 !important; }
    #skills-content-container .additional-info-block .button:hover {
      background-color: transparent;
      max-width: 100%;
      border-radius: 15px;
      margin: 1rem auto 0;
      display: block;
      color: #E61853 !important;
      border: 1px solid #E61853 !important; }

#skills-content-container .skill-content-block h3 img {
  display: inline-block; }

@media print {
  .top-bar-left {
    width: 150px; }
  h1#reportTitle, h2.identifiedGaps-Title {
    font-size: 1.8rem; }
  h2.identifiedGaps-Title {
    margin-bottom: 15rem; }
  #skills-report-block img {
    width: 50px; }
  #skills-report-block .cell {
    width: 93%; }
  .skills-report {
    border-bottom: 1px solid #000; }
  .printOnly {
    display: block; }
  .printHide {
    display: none; }
  .imgPrintFix {
    padding-bottom: 50px; }
  .what-next {
    height: 450px; } }
