


section{
  position: relative;
  padding: 20% 4%;

  @include breakpoint(medium) {
    padding: 4% 4% 8% 4%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  p,
  blockquote,
  th,
  td {
    text-align: center;
  }

  @include breakpoint(medium) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    p,
    blockquote,
    th,
    td {
      text-align: center;
    }
  }

  img{
    margin: 0 auto;
    display: block;
  }
}

section.narrow{
  padding: 8% 4%;
  @include breakpoint(small) {
    padding: 20% 4%;
  }

  @include breakpoint(medium) {
    padding: 8% 26%;
  }



  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    text-align: center;
  }

  img{
    margin: 0 auto;
    display: block;
  }

  .row{
    //max-width: 45rem;
  }
}

// Section divider
section.section-divider {
  @include grid-row();
  max-width: 100%;
  hr {
    @include grid-column(12);
     max-width: 100%;
    border-bottom: 5px solid $light-gray;
    box-sizing: border-box;
    margin: 0;
  }
}

section.pink{
   background-color: get-color(primary);
   color: $white;
}

section.opacity{
  opacity: 0.7;
}


#section1{
  padding: 30px 4% 8% 4%;
  @include breakpoint(medium) {
    padding: 130px 4% 8% 4%;
  }

  h1{
    color: $white;
    //font-size: 64px;
    @include breakpoint(medium) {
    margin-bottom: 50px;
    font-size: 62px;
  }
  
    /*
    &:after{
      content: "";
      position: absolute;
      background-color: get-color(primary);

      top: 65px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 84px;
      height: 2px;

      @include breakpoint(medium) {
        top: 208px;
        width: 179px;
        height: 3px;
      }
      @include breakpoint(large) {
        top: 208px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 154px;
        height: 4px;
      }
      
    }
    */
  }

  h2{
    color: get-color(primary);
    padding: 0 40px;

    @include breakpoint(medium) {
    line-height: 40px;
    font-size: 40px;
  }

  }
  
  form{
    margin-top: 29px;

      input{
        max-width: 393px;
        margin: 0 auto;
        height: 50px;
        background: transparent;
        padding: 16px;
        color: $white;

        &::placeholder{
          color: $white;
        }
      }

      button,a{
        border: 1px solid $white;
        margin: 0 auto;
        padding: 16px;
        display: block;
            
        max-width: 380px;

        @include breakpoint(medium) {
          font-size: 18px;
        }
      }
  }

}

#section2{
  background: url('../img/top-bg-2.png') no-repeat top center scroll; 
  background-size: contain;

  padding: 85px 4% 6% 4%;
  @include breakpoint(medium) {
    padding: 148px 4% 10% 4%;
  }
  
  h2{
    font-size: 32px;
    margin-right: 5px;
    margin-left: 5px;
    color: $white;
    margin: 1rem 1rem 2.5rem 1rem;
  }

  p{
    color: $white;
    //font-size: 19px;
    margin-bottom: 25px;
     @include breakpoint(medium) {
    text-align: left;
  }
  }
  
  #vid-container{
    position: relative;
  
    margin-left: 10px;
    margin-right: 10px;

    @include breakpoint(medium) {
    margin-left: 30px;
    margin-right: 30px;
  }
    


    #vid-overlay{
    background-color: get-color(primary);
    position: relative;

    padding: 29px;
    margin-top: 30px;
    margin-bottom: 30px;


    @include breakpoint(medium) {
      position: absolute;
      
      bottom: -79px;
      right: -34px;
      width: 377px;
      height: 210px;
      padding: 29px;
    }
      
      &:before {
        position:absolute; right:-1px; top:-1px; content:'';
        border-top: 30px solid get-color(secondary);
        border-left: 30px solid transparent;
      }

      &:after {
        position:absolute; left:-1px; bottom:-1px; content:'';
        border-top: 30px solid transparent;
        border-left: 30px solid get-color(secondary);
      }

      h3{
        color: white;
        //font-size: 25px;
        text-align: left;
        line-height: 33px;
      }

      p{
        text-align: left;
        margin-bottom: 0;
      }
    }
  }

}

#section3{

   h2{
    font-size: 32px;
    margin-right: 5px;
    margin-left: 5px;
    color: $white;
    margin-bottom: 9px;
  }
  
  p{
    color: $white;
    font-size: 19px;
    margin: 0 0 26px;
  }
  
  .left-panel{

  }

  @include breakpoint(medium) {
    .right-panel{
      margin-top: 25px;
      h2{
        text-align: left;
        margin-bottom: 24px;
      }

      p{
        text-align: left;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

#section4{
  padding: 35px;

  .grid-container{
    padding-right: 0;
    padding-left: 0;
  }

  @include breakpoint(large) {
    padding: 138px 160px 85px;
  }

   @include breakpoint(medium) {
    .cell:nth-child(odd) {
      padding-right: 13px;

    }

    .cell:nth-child(even) {
      padding-left: 13px;
      
    }
  }

  .card{
    
    padding-left: 10px;
    padding-right: 10px;

    @include breakpoint(medium) {
      padding-left: 30px;
      padding-right: 30px;
    }
    padding-top: 160px;
  
    @include breakpoint(large) {
      padding-left: 150px;
      padding-right: 30px;
      padding-top: 20px;
    }


    position: relative;
    min-height: 230px;
    background-color: transparent;
    border: 1px solid get-color(primary);
    

    
    img{
      left: 0;

      @include breakpoint(medium down) {
        right: 0;
      }

      @include breakpoint(large) {
        left: 34px;
      }

      position: absolute;
      top: 34px;
      width: 112px;
      overflow: hidden;
      border-radius: 50%;
      border: 3px solid get-color(primary);

      }

    @include breakpoint(medium) {
      h5,p{
        text-align: left;
      }
    }

    h5{
      color: $white;
      
    }

    p{
      color: $white;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .button{
    margin: 36px auto 0;
    display: block;
    max-width: 300px;

  }
}

#section5{
  background-color: $white;
  //opacity: 0.8;
  
  @include breakpoint(large) {
    padding: 115px 160px;
  }
  
  @include breakpoint(medium) {
    @include breakpoint(landscape only) {
      padding: 115px 50px;
    }
  }

  @include breakpoint(xlarge) {
    padding: 115px 160px;
  }
  

  #experts{
    max-width: 420px;
    float: left;
    width: 100%;
    margin-top: 7px;
  }

  p{
    text-align: left;
  }

  #members-grid{
    margin-top: 48px;
    padding: 0 33px;

    .cell{
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    .card{
      background-color: transparent;
      border: none;

      h5{
        margin-top: 18px;
        font-size: 18px;
        margin-bottom: 0;
      }

      img{
      width: 112px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      border: 7px solid get-color(primary);

      }

      p{
        text-align: center;
      }

    }

  }

  .grid-seperator{

    h5{
      color: get-color(primary);
      position: relative;
      z-index: 1;
      margin-top: 20px;

      &:before {

          content:"";
          margin: 0 auto; /* this centers the line to the full width specified */
          position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
          top: 50%; left: 0; right: 0; bottom: 0;
          width: 95%;
          z-index: -1;
      }

      &:after {
          border-bottom: 2px solid $medium-gray;
          content: "";
          margin: 0 auto; /* this centers the line to the full width specified */
          position: absolute;
          top: 45%; left: 0; right: 0;
          width: 95%;
          z-index: -1;
      }

      span { 
          /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
          background: #fff; 
          padding: 0 15px; 
      }
      
    }
  }

  #member-icon{
    padding: 41px 0 1px;
    background: url('../img/members-bg.png') no-repeat top center scroll; 
    background-size: cover;

    margin-top: 41px;

    h2{
      font-size: 31px;
      color: $white;
    }

    p{
      text-align: center;
      color: white;
    }
    
    .button{
      margin: 40px auto;
    display: block;
    width: 230px;
    }
  }
      

}

#section6{
  padding: 4% 4% 4% 4%;
  h2, p{
    color: $white;
  }
}

#section7{
      padding: 97px 4%;


      form{
          margin-top: 46px;

          label{
            color: $white;
          }
          

          input,select,textarea{
            min-height: 50px;
            background: transparent;
            padding: 14px;
            color: $white;

            &::placeholder{
              color: $white;
            }
          }

        textarea{
              min-height: 185px;
        }

        button{
          border: 1px solid $white;
          margin: 0 auto;
          padding: 16px;
          display: block;
          font-size: 1rem;

          &.solid.blue{
          
            border: none;
            background-color: get-color(secondary);
            float: right;
            max-width: 250px;
            margin-top: 25px;
          }

        }

      
  }
}
