

.skill-content-block{

  .skillDescription{
    position: relative;
    max-height: 180px;
    overflow: hidden;
  }


  .skillDescription:after {
    position: absolute;
    bottom: 0;  
    height: 100%;
    width: 100%;
    content: "";
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+93&0+24,0.9+55,1+93 */
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 24%, rgba(255,255,255,0.9) 55%, rgba(255,255,255,1) 93%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 24%,rgba(255,255,255,0.9) 55%,rgba(255,255,255,1) 93%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 24%,rgba(255,255,255,0.9) 55%,rgba(255,255,255,1) 93%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        pointer-events: none; /* so the text is still selectable */
        left: 0;
  }
}


#skills-content-container {

    .additional-info-block {
        position: relative;


          i.more-info{
                content:'\f078';
                font-family:'Font Awesome 5 Pro';
                font-weight: lighter;
                position: absolute;
                bottom: 40%;
                
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: 30px;
                font-size: 30px;
                color: #ccc;
          }


        .button {
          background-color: $light-gray !important;
          max-width: 100%;
          border-radius: 15px;
          margin: 1rem auto 0;
          display: block;
          color: $dark-gray !important;
          border: 1px solid $light-gray !important;

          &:hover {
              

              background-color: transparent;
              max-width: 100%;
              border-radius: 15px;
              margin: 1rem auto 0;
              display: block;
              color: $primary-color !important;
              border: 1px solid $primary-color !important;

          }

      }
    }


    .skill-content-block {

      .sub-cat{
        span {
            // border-radius: 15px;
            // margin: 0.1rem;
        }
      }
      
      h3{
        img {
          display: inline-block;
        }
      } 
    }
}

